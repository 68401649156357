/** @jsx jsx */
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import { jsx } from 'theme-ui';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Image from 'gatsby-image';
import Box from '@fify.at/gatsby-theme-base/src/components/Box/Box';
import Flex from '@fify.at/gatsby-theme-base/src/components/Flex/Flex';
import Button from '@fify.at/gatsby-theme-base/src/components/Button';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid';
import { Divider as DividerStyle } from '@fify.at/gatsby-theme-base/src/components/Divider/Divider.styles';

export default function Text_Image({
  className,
  image,
  textLeft,
  title,
  subtitle,
  text,
  buttonText,
  buttonLink,
  buttonVariant,
  ...rest
}) {
  return (
    <Section className={className} {...rest}>
      <Flex
        sx={{
          alignItems: 'center',
          flexFlow: ['column', null, 'row'],
        }}
      >
        <Box
          sx={{
            width: ['100%', null, '50%'],
            order: textLeft ? [0, 0, 0, 1, 1] : [0, 0, 0, 0, 0],
          }}
        >
          {image && (
            <Image
              imgStyle={{ objectFit: 'contain' }}
              {...image.childImageSharp}
            />
          )}
        </Box>
        <Box sx={{ width: ['100%', null, '50%'] }}>
          <Box
            sx={{
              paddingRight: textLeft ? [0, null, 5] : 0,
              paddingLeft: textLeft ? 0 : [0, null, 5],
              paddingY: 6,
              position: 'relative',
            }}
          >
            <Heading
              sx={{
                color: '',
              }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {/*<div*/}
            {/*  sx={{*/}
            {/*    ...DividerStyle,*/}
            {/*    borderColor: '',*/}
            {/*  }}*/}
            {/*/>*/}
            {subtitle && (
              <Subheading
                sx={{
                  color: '',
                }}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
            <div
              sx={{ marginTop: 5 }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {buttonText && buttonLink && (
              <Row>
                <Col sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant={buttonVariant ? buttonVariant : 'onDark'}
                    size="large"
                    sx={{ paddingY: 3, paddingX: 5 }}
                    to={buttonLink}
                    dangerouslySetInnerHTML={{ __html: buttonText }}
                  />
                </Col>
              </Row>
            )}
          </Box>
        </Box>
      </Flex>
    </Section>
  );
}
