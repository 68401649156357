/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Col from '@fify.at/gatsby-theme-base/src/components/Grid/Col';
import Row from '@fify.at/gatsby-theme-base/src/components/Grid/Row';
import React from 'react';
import Alert from '@fify.at/gatsby-theme-base/src/components/Alert/Alert';
import {
  ControlledForm,
  handleNetlifySubmit,
  SubmitButton,
  TextArea,
  TextField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import * as Yup from 'yup';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Text_calltoaction from '../../components/raw_templates/Text_calltoaction';
import Text_Image from '../../components/raw_templates/Text_Image';

export default function PartnerInvestorWerden({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Partner / Investor werden"
      description="Gemeinsam zukunftssicher auf das nächste Level!"
      image={data.hero.childImageSharp.fluid}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="east"
        variant="alpha"
        title="Gemeinsam zukunftssicher auf das nächste Level!"
        image={data.hero}
      />

      <Text_calltoaction
        sectionBackground="white"
        title="WERDEN SIE JETZT INVESTOR BEI WIRKAUFENDEINHAUS.AT"
        text="<p>Wir gehen in punkto Investments völlig neue, attraktive Wege.</p>"
        buttons={[
          {
            text:
              'Investieren Sie in WirKaufenDeinHaus.at <i class="fal fa-arrow-right"></i>',
            to: '/investor-partner/investor-werden/',
          },
        ]}
      />

      <Text_calltoaction
        sectionBackground="muted"
        title="GESUCHT: PARTNER FÜR UNSERE ERFOLGSMISSION"
        text="<p>Für bestehende Maklerunternehmen bieten wir hohe Planungssicherheit und
Erfolgsmöglichkeiten für die Zukunft.</p>"
        buttons={[
          {
            text:
              'Starten Sie jetzt als Partner <i class="fal fa-arrow-right"></i>',
            to: '/investor-partner/partner-werden/',
          },
        ]}
      />

      <Text_calltoaction
        sectionBackground="white"
        title="MACHEN SIE JETZT KARRIERE IN DER IMMOBILIENBRANCHE"
        text="<p>Sie wollten schon immer in der Immobilienbranchen Fuß fassen oder suchen als
Immobilienmakler neue Möglichkeiten und Herausforderungen? Wir kaufendeinhaus.at
macht’s möglich!<br/><strong>#immodurchstarter</strong></p>"
        buttons={[
          {
            text: 'Jetzt Karriere starten <i class="fal fa-arrow-right"></i>',
            to: '/investor-partner/partner-werden/',
          },
        ]}
      />

      {/*
      <Section sx={{ paddingY: '4rem' }}>
        <h1
          sx={{
            '&&': { padding: 0 },
            marginBottom: 5,
            textAlign: 'center',
            fontWeight: '800',
            color: 'primary',
          }}
        >
          Die Voraussetzungen
        </h1>

        <Row sx={{ marginTop: 5, textAlign: 'center' }}>
          <Col>
            <p>
              Unsere Partner und alle unsere Mitarbeiter zeichnen sich durch
              absolute Ehrlichkeit und Transparenz in allen Bereichen Ihres
              Handelns aus. <br />
              Freundlichkeit, Pünktlichkeit und Handschlagqualität gilt als die
              Basis-Voraussetzung für unser Team!
            </p>
          </Col>
        </Row>
        <h2
          sx={{
            '&&': { padding: 0 },
            marginBottom: 5,
            textAlign: 'center',
            fontWeight: '800',
            color: 'primary',
          }}
        >
          Ausbildung
        </h2>
        <Row sx={{ textAlign: 'center' }}>
          <Col>
            <p>
              Sie erhalten ein fundiertes Wissen rund um Immobilien,
              Kaufabwicklungen, sowie Immobilienbewertung. <br />
              Wir bieten eine eigene Akademie für Qualitätssicherung!
            </p>
          </Col>
        </Row>
      </Section>
      <Section
        sx={{
          maxWidth: [null, null, 500, 760, 1100],
          marginX: 'auto',
          boxShadow: `0 0px 65px rgba(0,0,0,0.1)`,
          backgroundColor: '#fff',
          marginBottom: 8,
        }}
      >
        <Heading
          sx={{ color: 'primary', fontWeight: 'bold', textAlign: 'center' }}
        >
          In 5 Schritten Partner werden
        </Heading>
        <Section container="fluid" sx={{ paddingTop: 6 }}>
          <Row>
            <Col>
              <p>
                <strong>1. Profi: </strong>
                Sie sind Immobilien-, Sanierungsprofi oder haben einfach durch
                Ihren Beruf (Handwerker, Versicherungsberater, Friseur, …)
                vielfach Kenntnis über freiwerdende Wohnungen oder Grundstücke.
              </p>
              <p>
                <strong>2. Kontakt: </strong>
                Bitte füllen Sie das Formular aus und wir melden uns in Kürze
                bei Ihnen.
              </p>
              <p>
                <strong>3. Termin: </strong>
                Bei einem persönlichen Termin stellen wir Ihnen unser
                Partner-Programm und die Verdienstmöglichkeiten vor.
              </p>
              <p>
                <strong>4. Kooperation: </strong>
                Wir definieren das Kooperationsmodell, halten dieses schriftlich
                fest und starten gemeinsam.
              </p>
              <p>
                <strong>5. Partner: </strong>
                Sie sind ein erfolgreicher Partner und erhalten im Erfolgsfall
                Ihr Honorar bzw. als Sanierungsprofi eine Auftragsbestätigung.
              </p>
            </Col>
          </Row>
        </Section>
      </Section>
      <Section background="#f9f9f9" sx={{ paddingY: '4rem' }}>
        <h1
          sx={{
            '&&': { padding: 0 },
            marginBottom: 5,
            textAlign: 'center',
            fontWeight: '800',
            color: 'primary',
          }}
        >
          Jetzt Partner oder Investor werden
        </h1>
        <ContactForm location={location} />
      </Section>
    */}
    </HomePageLayout>
  );
}

function ContactForm({ location }) {
  const [isSubmitted, setSubmitted] = React.useState(false);
  if (isSubmitted) {
    return <Alert variant="success">Danke für Ihre Nachricht!</Alert>;
  }
  return (
    <ControlledForm
      name="kontakt"
      initialValues={{
        vorname: '',
        nachname: '',
        strasse: '',
        plz: '',
        ort: '',
        land: '',
        telefon: '',
        email: '',
        anfrage: '',
      }}
      validationSchema={formSchema}
      onSubmit={(values, actions) =>
        handleNetlifySubmit(values, actions, location.href).then(() =>
          setSubmitted(true)
        )
      }
    >
      <Row>
        <Col>
          <TextField label="Vorname" name="vorname" required />
        </Col>
        <Col>
          <TextField label="Nachname" name="nachname" required />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField label="Straße" name="strasse" />
        </Col>
        <Col>
          <TextField label="PLZ" name="plz" />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField label="Ort" name="ort" />
        </Col>
        <Col>
          <TextField label="Land" name="land" />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField label="Telefon" name="telefon" required />
        </Col>
        <Col>
          <TextField label="E-Mail" name="email" required />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea label="Ihre Anfrage" name="anfrage" rows="5" />
        </Col>
      </Row>

      <SubmitButton>Senden</SubmitButton>
    </ControlledForm>
  );
}

const formSchema = Yup.object().shape({
  vorname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  nachname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  telefon: Yup.string().required('Dieses Feld ist verpflichtend'),
  email: Yup.string()
    .trim()
    .email('Bitte eine gültige E-Mail-Adresse eingeben')
    .required('Dieses Feld ist verpflichtend'),
});

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-immobilien" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    max: file(name: { eq: "home_reg_experte" }) {
      childImageSharp {
        fluid(maxWidth: 664, maxHeight: 800, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_1: file(name: { eq: "person_1" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_2: file(name: { eq: "person_2" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_3: file(name: { eq: "person_3" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_4: file(name: { eq: "person_4" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    award: file(name: { eq: "logo" }) {
      childImageSharp {
        fixed(height: 128, width: 96) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    site {
      siteMetadata {
        ratgeberList {
          slug
          url
          name
        }
      }
    }
    ratgeber_img: file(name: { eq: "ratgeber_img" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
