/** @jsx jsx */
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import { jsx } from 'theme-ui';
import React from 'react';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import { Row, Col } from '@fify.at/gatsby-theme-base/src/components/Grid';
import Button from '@fify.at/gatsby-theme-base/src/components/Button/Button';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';

export default function Text_calltoaction({
  sectionBackground,
  title,
  subtitle,
  text,
  buttons,
  ...rest
}) {
  return (
    <Section background={sectionBackground ? sectionBackground : ''} {...rest}>
      <div sx={{ marginBottom: 7 }}>
        {title && <Heading dangerouslySetInnerHTML={{ __html: title }} />}
        {subtitle && (
          <Subheading dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}
      </div>
      <div
        sx={{ textAlign: 'center' }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {buttons ? (
        <Row>
          <Col sx={{ display: 'flex', justifyContent: 'center' }}>
            {buttons.map(b => (
              <Button
                variant={b.buttonVariant ? b.buttonVariant : 'onDark'}
                size="large"
                sx={{ paddingY: 3, paddingX: 5, marginRight: 3 }}
                to={b.to}
                href={b.href}
                dangerouslySetInnerHTML={{ __html: b.text }}
                target={b.target}
              />
            ))}
          </Col>
        </Row>
      ) : (
        ''
      )}
    </Section>
  );
}
